<template>
  <div :class="className">
    <van-nav-bar
      title="感知报警"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in alarmRecordsConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName, query: item.routerQuery || {} }"
        :border="idx !== alarmRecordsConf.length - 1"
      >
        <template #title>
          <van-icon
            :name="item.icon"
            :color="item.iconColor"
            class-prefix="iconfont"
            class="van-cell__left-icon"
          />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AlarmRecordsDispatch",
  data() {
    return {
      className: "alarm-records-dispatch",
      alarmRecordsConf: [
        {
          icon: "ganzhibaojing",
          iconColor: "#FC4935",
          title: "感知报警",
          routerName: "Alarm",
          router: "alarm"
        },
        {
          icon: "keranyoudubaojing",
          iconColor: "#23A86F",
          title: "可燃有毒报警",
          routerName: "PoisonAlarm",
          router: "poison-alarm"
        },
        {
          icon: "shipinbaojing",
          iconColor: "#1D9EFF",
          title: "视频报警",
          routerName: "VideoAlarmList",
          router: "videoalarm"
        },
        {
          icon: "renyuandingweibaojing",
          iconColor: "#FFB41F",
          title: "人员定位报警",
          routerName: "PositionAlarms",
          router: "videoalarm"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      authRoutes: state => state.menu.roles
    })
  },
  created() {
    this.initAlarmRecordsConf();
  },
  methods: {
    initAlarmRecordsConf() {
      this.alarmRecordsConf = this.alarmRecordsConf.filter(v =>
        this.authRoutes.includes(v.router)
      );
    }
  }
};
</script>

<style lang="scss">
.alarm-records-dispatch {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    span {
      color: #333333;
      font-size: 15px;
    }
  }
}
</style>
